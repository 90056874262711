@use "../theme/index" as theme;
@keyframes breathIn {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(2);
  }
}
@keyframes breathOut {
  from {
    transform: scale(2);
  }

  to {
    transform: scale(1);
  }
}
@keyframes holdIn {
  from {
    transform: scale(2);
  }

  to {
    transform: scale(2);
  }
}
@keyframes holdOut {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(1);
  }
}
.meditation {
  &__open-button {
    width: 60%;
    display: block;
    margin: 0 auto 20px;
    line-height: initial;
  }

  &__modal {
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;

    .modal {
      &__header {
        height: 50px;
        display: flex;
        justify-content: flex-end;
      }

      &__close-btn {
        width: 50px;
        font-size: 24px;
        background-color: transparent;
        border: 1px solid theme.$black;
        cursor: pointer;
      }

      &__body {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
      }
    }
  }

  &__circle {
    position: relative;

    circle {
      transform: rotate(-90);
      fill: transparent;
      stroke: theme.$yellow;
      stroke-width: 5;
    }

    circle:nth-child(2n) {
      fill: transparent;
      stroke: theme.$green;
      stroke-width: 5;
    }

  }

  &__step {
    position: absolute;
    width: 300px;
    text-align: center;
    text-transform: uppercase;
    color: theme.$grey;
    font-weight: bold;
    top: 45%;
    animation-timing-function: linear;
    animation-delay: 0s;
    animation-fill-mode: forwards;
  }

  &__description {
    height: 200px;
    min-width: 200px;
  }

  &__text {
    color: theme.$white;
    font-size: 24px;
    text-transform: capitalize;
    display: none;
    margin-bottom: 10px;

    &--show {
      display: block;
    }
  }

  &__last-message {
    display: none;

    &--show {
      display: block;
    }
  }
}
