@use "../theme/index" as theme;

.review-area {
  display: flex;
  margin-bottom: 20px;
  justify-content: center;

  &__card-place {
    width: 310px;
    @include theme.respond-to(largedesktop) {
      width: 400px;
    }
    @include theme.respond-to(widedesktop) {
      width: 416px;
    }
  }

  &__card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid theme.$alto;
    width: 100%;
    padding: 0;
    background: none;
    &:focus {
      outline: none;
    }
  }

  &__title-percent {
    height: 5px;
    width: 100%;
    background-color: theme.$grey;
  }

  &__title {
    display: flex;
    justify-content: space-between;
    padding: 5px;
    width: 100%;
    color: theme.$black;
    background-color: theme.$grey;
    overflow: hidden;

    @include theme.respond-to(largedesktop) {
      min-height: 75px;
    }

    &__text {
      border: none;
      font-size: 19px;
      font-weight: bold;
      text-align: start;
      padding: 5px;
      cursor: pointer;
      &:hover {
        color: theme.$lightGray;
      }
      @include theme.respond-to(largedesktop) {
        font-size: 22px;
      }
    }
  }

  &__title-wrapper {
    display: flex;
    align-content: flex-start;
  }

  &__bottom {
    display: flex;
    flex-direction: row;
    height: 50px;
    width: 100%;
    bottom: 0;
    right: 0;
  }

  &__btn {
    width: 50%;
  }

  &__button {
    min-height: 20px;
    line-height: 16px;
    width: 100%;
    height: 100%;
    font-size: 16px;
    font-weight: bold;
    bottom: 0;
    &:hover {
      color: theme.$white;
    }

    @include theme.respond-to(largedesktop) {
      font-size: 21px;
    }
  }

  &__image-wrapper {
    height: 200px;
    display: flex;
    justify-content: center;
  }

  &__image {
    height: 100%;
  }
}
