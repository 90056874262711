@use "../theme/index" as theme;

.contact-line{
  &__border{
    height: 50px;
    background-color: theme.$lightGreen;
  }

  &__info {
    height: 306px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-right: 3%;
    padding-left: 3%;
    @include theme.respond-to(tablets) {
      height: 190px;
      flex-direction: row;
    }
    @include theme.respond-to(desktop) {
      height: 238px;
    }
    @include theme.respond-to(widedesktop) {
      height: 258px;
    }

    &__cry-picture {
      width: 80px;
      height: 62px;
      margin-top: 10px;
      margin-bottom: 10px;
      align-self: center;
      @include theme.respond-to(tablets) {
        margin-top: 0;
        margin-bottom: 0;
      }
      @include theme.respond-to(desktop) {
        width: 110px;
        height: 94px;
      }
      @include theme.respond-to(largedesktop) {
        width: 165px;
        height: 132px;
      }
      @include theme.respond-to(widedesktop) {
        width: 238px;
        height: 189px;
      }
    }

    &__text {
      display: flex;
      flex-direction: row;
    }

    &__numbers {
      color: theme.$blackApprox;
      font-size: 14px;
      align-self: center;
      @include theme.respond-to(tablets) {
        font-size: 13px;
      }
      @include theme.respond-to(desktop) {
        font-size: 15px;
      }
      @include theme.respond-to(largedesktop) {
        font-size: 19px;
      }
      @include theme.respond-to(widedesktop) {
        font-size: 23px;
      }
      @include theme.respond-to(bigdesktop) {
        font-size: 30px;
      }

      &__text-line {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        @include theme.respond-to(desktop) {
          flex-direction: row;
        }
      }

      &__life-line {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        justify-content: center;
        margin-top: 10px;
        @include theme.respond-to(desktop) {
          flex-direction: row;
          margin-top: 41px;
        }
      }

      &__title {
        margin-right: 6px;
      }

      &__number {
        font-weight: bold;
        color: theme.$darkRed;
        margin-top: 10px;
        @include theme.respond-to(desktop) {
          margin-top: 0;
          margin-left: 6px;
        }
      }
    }

    &__pictures {
      margin-top: 10px;
      align-self: center;
      @include theme.respond-to(tablets) {
        margin-top: 71px;
        align-self: normal;
      }
      @include theme.respond-to(largedesktop) {
        margin-top: 30px;
      }
      @include theme.respond-to(widedesktop) {
        margin-top: 10px;
      }

      &__run {
        width: 51px;
        height: 78px;
        margin-top: 46px;
        @include theme.respond-to(desktop) {
          width: 65px;
          height: 111px;
          margin-top: 0;
          margin-bottom: 5px;
        }
        @include theme.respond-to(largedesktop) {
          width: 89px;
          height: 137px;
          margin-bottom: 0;
        }
        @include theme.respond-to(widedesktop) {
          width: 106px;
          height: 163px;
        }
      }

      &__tree {
        max-width: 80%;
        width: 250px;
        height: 124px;
        margin-bottom: -1px;
        @include theme.respond-to(desktop) {
          width: 316px;
          height: 185px;
        }
        @include theme.respond-to(largedesktop) {
          width: 437px;
          height: 217px;
        }
        @include theme.respond-to(widedesktop) {
          width: 520px;
          height: 258px;
        }
      }
    }
  }

  &__title {
    background-color: theme.$blackApprox;

    &__text {
      color: theme.$white;
      font-size: 25px;
      padding: 24px 3% 24px;
      @include theme.respond-to(desktop) {
        font-size: 38px;
        padding: 26px 3%;
      }
      @include theme.respond-to(largedesktop) {
        font-size: 48px;
      }
    }
  }
}
