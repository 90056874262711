@use "../theme/index" as theme;

.users {

  &__content {
    display: flex;
    flex: 1.3 1;
    height: 61px;
    border: solid 1px #d2d2d2;
    background-color: #fff;
    font-size: 24px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 300px;
    margin: 5px 0;
  }

  &__input {
    width: 100%;
    border: none;
    padding: 10px 36px;
  }

  &__add-btn {
    border: solid 1px #d2d2d2;
    width: 70px;
    font-size: 24px;
    &:hover {
      cursor: pointer;
    }
  }
}
