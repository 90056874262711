@use "../theme/index" as theme;

.journals-card-section {
  &__list {
    display: flex;
    flex-wrap: wrap;
    padding: 8px;
    @include theme.respond-to(largedesktop) {
      padding: 25px;
    }
  }

  &__item {
    list-style: none;
    padding: 0;
    width: 100%;
    @include theme.respond-to(tablets) {
      width: 50%;
    }
    @include theme.respond-to(desktop) {
      width: 33.3%;
    }
    @include theme.respond-to(bigdesktop) {
      width: 25%;
    }
  }

  &__footer {
    position: relative;
    height: 100px;
  }

  &__button-load {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 16px;
    width: 300px;
    height: 43px;
    padding: 0;
    border: solid 1px theme.$alto;
    background-color: theme.$white;
    font-size: 17px;
    font-weight: bold;
    cursor: pointer;

    &:focus {
      outline: none;
    }

    @include theme.respond-to(tablets) {
      width: 375px;
      height: 48px;
      font-size: 20px;
    }
    @include theme.respond-to(desktop) {
      width: 468px;
      height: 60px;
      font-size: 24px;
    }
    @include theme.respond-to(largedesktop) {
      margin-bottom: 50px;
    }
  }
}
