@use "../../theme/index" as theme;

.material {
  &.gritx-btn {
    min-height: 20px;
    line-height: 16px;
    width: 100%;
  }

  &--inactive.gritx-btn {
    height: 100%;
    font-size: 13px;
    @include theme.respond-to(largedesktop) {
      font-size: 16px;
    }
  }

  &--active.gritx-btn {
    position: absolute;
    height: 20%;
    font-size: 16px;
    font-weight: bold;
    bottom: 0;
    &:hover {
      color: theme.$white;
    }

    @include theme.respond-to(largedesktop) {
      font-size: 21px;
    }
  }
}
