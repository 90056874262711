@use "../theme/index" as theme;

.library-filters {
  &__situations {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 23px;
  }

  &__button {
    width: 245px;
    margin: 5px 15px 5px auto;
  }
}
