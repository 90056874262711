@use "components/theme/index" as theme;

.file-uploader {
  margin-bottom: 20px;
  &__title{
    @include theme.textSize;
  }
  &__error {
    font-size: 18px;
    color: theme.$darkRed;
  }
  &__button{
    background-color: theme.$semiDarkGrey;
    padding: 10px 20px;
    color: theme.$darkGrey;
    cursor: pointer;
  }
}

.dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: theme.$white;
  border-style: dashed;
  background-color: transparent;
  transition: border .24s ease-in-out;

  &--active {
    border-color: theme.$blue;
  }

  &--accept {
    border-color: theme.$green;
  }

  &--reject {
    border-color: theme.$darkRed;
  }
}