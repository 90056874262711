@use "../theme/index" as theme;

.interview {
  &__buttons {
    & .gritx-btn {
      margin-bottom: 20px;
      height: 62px;
    }

    @include theme.respond-to(tablets) {
      display: flex;
      justify-content: center;
      align-items: center;
      & .gritx-btn {
        width: 48%;
        max-width: 245px;
      }
    }
  }

  &__buttons {
    &--last-entry {
      .gritx-btn {
        &:first-child {
          margin-right: 0;
        }
      }
    }
  }

  &__page {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 20px 20px;
    background-color: theme.$white;
    @include theme.respond-to(tablets) {
      margin: 0 30px 20px;
    }
    @include theme.respond-to(largedesktop) {
      margin: 0 50px 20px;
    }

    &-number {
      margin-top: 20px;
      font-size: 18px;
      font-weight: bold;
      text-transform: capitalize;
      @include theme.respond-to(tablets) {
        font-size: 24px;
      }
    }

    .overlay {
      max-width: 80%;
      margin: 0 auto;

      @include theme.respond-to(largedesktop) {
        max-width: 1000px;
      }
    }
  }

  &__loader {
    min-height: 200px;
    position: relative;
  }

  @keyframes buttonFade {
    from {
      background-color: theme.$grey;
    }
    to {
      background-color: theme.$red;
    }
  }
}
