@use "../../components/theme/index" as theme;

.article-authors-page {
  font-size: 1.2vw;
  padding: 0 25px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include theme.respond-to(largedesktop) {
    margin-top: 25px;
  }

  &-loader {
    display: flex;
    justify-content: center;
  }

  &-breadcrumbs {
    padding: 25px;
    font-size: 2vw;
    color: theme.$darkGrey;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;

    &__previous {
      text-transform: uppercase;
      font-weight: bold;
      color: theme.$darkGrey;

      &:visited {
        color: theme.$darkGrey;
      }

      &:hover {
        color: theme.$green
      }
    }
    &-item {
      margin-left: 5px;
    }
  }

  &-header {
    margin-bottom: 20px;
    background-color: theme.$grey;
    padding: 10px;
    display: flex;
    justify-content: space-between;

    &__title {
      font-size: 18px;
      margin: 0;
      @include theme.respond-to(tablets) {
        font-size: 24px;
        padding: 10px 20px;
      }
      @include theme.respond-to(largedesktop) {
        font-size: 36px;
        padding: 10px 40px;
      }
    }
  }
}

.article-authors-body {
  padding: 25px;

  &-author {
    line-height: 1.5em;
  }
}