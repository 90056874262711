@use "../theme/index" as theme;

.email-area {
  width: 100%;
  height: 558px;
}

.email-zone {
  width: 100%;
  height: 498px;
  border: 1px solid theme.$alto;
  background-color: theme.$white;
  font-size: 16px;
  color: theme.$darkGrey;
  @include theme.respond-to(tablets) {
    font-size: 24px;
  }

  &__title {
    display: flex;
    align-items: center;
    width: 100%;
    height: 72px;
    background-color: theme.$grey;
    font-weight: bold;
    padding: 0 36px 0 36px;
    margin: 0;
    border-bottom: 1px solid theme.$alto;
  }

  &__data{
    width: 100%;
    height: 426px;
    display: flex;
    flex-direction: column;
  }

  &__form {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    min-height: 62px;
    border-bottom: 1px solid theme.$alto;
    padding: 16px 19px 16px 36px;
    background-color: theme.$white;
  }

  &__input {
    width: 90%;
    resize: vertical;
    max-height: 87px;
    margin: 0 12px 0 12px;
    border: none;

    @include theme.respond-to(tablets) {
      min-height: 32px;
    }
  }

  &__add-btn {
    border: none;
    padding: 0;
    background: none;
    width: 24px;
    height: 24px;
    cursor: pointer;
    &:focus {
      outline: none;
    }
    &:disabled {
      fill: theme.$alto;
      cursor: default;
    }
  }

  &__plus {
    width: 100%;
    height: 100%;
  }

  &__output {
    display: flex;
    flex: 1 1 auto;
    flex-wrap: wrap;
    overflow-y: auto;
    align-content: flex-start;
    padding: 0 0 14px 19px;
  }

  &__footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    bottom: 0;
    height: 61px;
    padding: 5px;
    border-top: 1px solid theme.$alto;
    @include theme.respond-to(tablets) {
      padding: 0 19px 0 19px;
    }
  }

  &__cc-to-self {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
  }

  &__check {
    display: flex;
    height: 100%;
    align-items: center;
    padding-left: 30px;
    @include theme.respond-to(tablets) {
      padding-left: 56px;
    }
  }

  &__check-input {
    position: absolute;
    display: none;

    &:checked + .email-zone__check-box {
      background-image: url(../../assets/image/materials/ok.svg);
      background-color: transparent;
    }

    &:disabled + .email-zone__check__box {
      background-color: theme.$grey;
      background-image: none;
      cursor: default;
    }
  }

  &__check-box {
    position: absolute;
    width: 25px;
    height: 25px;
    line-height: 20px;
    border: solid 1px theme.$alto;
    padding: 0;
    margin-left: -30px;
    background-color: transparent;
    background-image: none;
    cursor: pointer;
    @include theme.respond-to(tablets) {
      width: 36px;
      height: 36px;
      margin-left: -56px;
    }
  }

  &__to-self-info {
    height: 40px;
    width: 40px;
    margin-bottom: 20px;
    cursor: pointer;
    fill: theme.$alto;
    &:hover{
      fill: theme.$darkGrey;
    }
  }

  &__remove-all-btn.gritx-btn {
    padding: 0;
    min-height: 20px;
    line-height: 16px;
    width: 105px;
    height: 30px;
    font-size: 16px;
    font-weight: normal;
    color: theme.$darkGrey;
    @include theme.respond-to(tablets) {
      font-size: 24px;
      width: 159px;
      height: 36px;
    }
    &:disabled {
      background-color: theme.$grey;
    }
  }

  &__error {
    font-weight: bold;
    margin-left: 19px;
    color: theme.$red;
    font-size: 16px;

    @include theme.respond-to(tablets) {
      font-size: 24px;
    }
  }
}
