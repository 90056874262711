@use "../theme/index" as theme;

.modal-privacy-terms{
  .modal__close-btn{
    float: right;
    line-height: 19px;
    padding: 0;
    font-size: 25px;
    font-weight: 300;
    border: none;
    background: none;
    margin-top: 20px;
    margin-right: 20px;
    cursor: pointer;

    @include theme.respond-to(tablets) {
      line-height: 23px;
      font-size: 35px;
      margin-top: 40px;
      margin-right: 40px;
    }
    @include theme.respond-to(desktop) {
      line-height: 34px;
      font-size: 45px;
      margin-top: 70px;
      margin-right: 70px;
    }
  }
}

.modal{
  &__overlay{
    position: fixed;
    z-index: 25;
    top: 0;
    padding: 15px;
    background-color: rgba(theme.$blackApprox, 0.5);
    backdrop-filter: blur(5px);
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    @include theme.respond-to(tablets) {
      padding: 25px;
    }
    @include theme.respond-to(desktop) {
      padding: 50px;
    }
  }
  &__window{
    background-color: theme.$grey;
    height: calc(100vh - 30px);
    flex: 1 1 auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    @include theme.respond-to(tablets) {
      height: calc(100vh - 50px);
    }
    @include theme.respond-to(desktop) {
      height: calc(100vh - 100px);
    }
  }
  &__body{
    flex: 1 1 auto;
    overflow: auto;
  }
}