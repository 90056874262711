@use "../theme/index" as theme;

.avatars {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: 17px;
  border: none;
  background: transparent;
  position: relative;

  &__content {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  &__item {
    width: 163px;
    height: 163px;
    padding: 10px 0 0;
    border: 1px solid theme.$alto;
    border-radius: 50%;
    background: transparent;
    overflow: hidden;
    display: flex;
    align-items: flex-end;
    justify-content: center;
  }

  &__img {
    width: 100%;
    height: 100%;
    max-width: 140px;
    max-height: 140px;
  }

  &__btn {
    position: absolute;
    bottom: -10px;
    right: 15px;
    width: 44px;
    height: 44px;
    border: 1px solid theme.$alto;
    border-radius: 50%;
    background: theme.$lightGreen;

    &:hover {
      cursor: pointer;
    }

    &-icon {
      fill: theme.$green;
    }
  }
}
