@use "sass:color";
@use "../../components/theme/index" as theme;

.file-preview {
  width: 180px;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  &__image {
    max-width: 100%;
    max-height: 100%;
    &-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 130px;
    }
  }

  &__name {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &__delete {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
      background-color: color.adjust(theme.$grey, $lightness: -6%);
    }

    &-img {
      width: 20px;
    }
  }
}