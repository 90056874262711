@use "components/theme/index" as theme;

.input-date {
  display: flex;
  flex-direction: column;
  @include theme.textSize;

  & {
    min-height: 6.5em;
  }

  &__label {
    display: flex;
    flex-direction: column;

    &-text {
      margin-bottom: 10px;
    }
  }

  &__required {
    color: theme.$darkRed;
  }

  &__field {
    border: 1px solid theme.$black;
    background-color: white;
    border-radius: 0 !important;

    &--error {
      border: 1px solid theme.$darkRed;
    }

    & input {
      padding: 10px;
      line-height: 1.5;
      font-size: 20px;
      color: black;
      font-family: "Open Sans", sans-serif;
      border-radius: 0;
    }

    & fieldset {
      border: none;
    }
  }

  &__error {
    display: block;
    color: theme.$darkRed;
    font-size: 16px;
  }
}