@use "sass:color";
@use "../../components/theme/index" as theme;

.participants {

  &__header {
    margin-bottom: 20px;
  }

  &__body {
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    padding: 8px;
  }

  &__item {
    list-style: none;
    border: 1px solid #d2d2d2;
    padding: 5px;
    background: none;
    margin: 5px;
  }

  &__title {
    background-color: theme.$grey;
    padding: 10px;
    display: flex;
    justify-content: right;
  }

  &__button.gritx-btn {
    width: 140px;
    font-weight: 500;
    min-height: 40px;
    line-height: 38px;
    margin: 0px 5px;
  }

  .loader__wrapper {
    margin: 0 auto 40px;
  }

  &__modal {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    max-width: 1120px;
    margin: 0 auto;
    height: 100%;
    max-height: 60vh;
    padding: 20px;
    overflow: auto;

    .modal {
      &__header {
        display: none;
      }

      &__body {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        position: relative;
        padding: 0 10px;
        height: 100%;

        .bots {
          @include theme.respond-to(tablets) {
            width: 50%;
            padding: 17px;
            margin: 0;
          }
          @include theme.respond-to(desktop) {
            width: 33.3%;
          }

          &__item {
            cursor: pointer;
          }
        }
      }
    }
  }
}

.participant-card {
  display: flex;
  padding: 5px;

  &__avatar {
    margin: 5px;
  }

  &__name {
    text-align: center;
    font-size: 24px;
    padding: 10px;
  }

  &__image {
    width: 40px;
    height: 38px;
    fill: #fc767d;
  }

  &__btn {
    cursor: pointer;
    border: none;
    background: none;
    font-size: 24px;
    font-weight: bold;

    &:hover {
      background-color: color.adjust(theme.$grey, $lightness: -6%)
    }
  }
}