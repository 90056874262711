@use "../../components/theme/index" as theme;

.logout {
  position: relative;

  &__loader {
    max-width: 150px;
    margin: auto;
  }
}
