@use "../theme/index" as theme;

.layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.container {
  max-width: 1920px;
  margin: 0 auto;

  &__wrapper{
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}

.main {
  margin-top: 100px;
  flex: 1 1 auto;
  overflow-x: hidden;
  @include theme.respond-to(tablets) {
    padding-top: 25px;
  }
  @include theme.respond-to(desktop) {
    padding-top: 50px;
  }
  @include theme.respond-to(mobile) {
    margin-top: 70px;
  }
}

.stop-scrolling {
  height: 100%;
  overflow: hidden;
}
