@use "../theme/index" as theme;

.text-box {
  display: flex;
  flex-direction: column;
  @include theme.textSize;
  & {
    min-height: 9.5em;
  }

  &__label {
    display: flex;
    flex-direction: column;

    &-text {
      margin-bottom: 10px;
    }
  }

  &__required {
    color: theme.$darkRed;
  }

  &__field {
    padding: 10px;
    line-height: 1.5;
    resize: vertical;
    max-height: 500px;
    border: 1px solid theme.$black;

    &--error{
      border-color: theme.$darkRed;
    }
  }

  &__error {
    display: block;
    color: theme.$darkRed;
    font-size: 16px;
  }
}