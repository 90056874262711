@use "../../components/theme/index" as theme;

.xpedition {
  position: relative;
  min-height:400px;

  &__title {
    display: flex;
    align-items: center;
    font-size: 48px;
    font-weight: bold;
    text-align: left;
    color: theme.$darkGrey;
    margin: 20px 10px 10px;
    text-transform: uppercase;

    @include theme.respond-to(tablets) {
      margin: 0 0 44px 51px;
    }

    @include theme.respond-to(mobile) {
      display: none;
    }
  }

  &__icon {
    width: 50px;
    height: 50px;
    margin-right: 6px;
    padding: 7px;
  }
}
