@use "../../theme/index" as theme;

.xpedition-list {

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 41px 100px;
    @include theme.respond-to(mobile) {
      padding: 0;
    }
    @include theme.respond-to(largedesktop) {
      flex-wrap: nowrap;
    }
  }

  &__chat {
    margin: 5px 15px 20px;
    width: 100%;
    position: relative;
    @include theme.respond-to(mobile) {
      margin: 0;
    }
    @include theme.respond-to(largedesktop) {
      width: 50%;
    }
    @include theme.respond-to(mobile) {
      display: none;
    }
  }

  &__chat-toolbar {
    background-color: theme.$grey;
    height: 60px;
    padding: 0 20px;
    display: flex;
    align-items: center;
  }

  &__bot-avatar {
    width: 40px;
    height: 38px;
    fill: theme.$red;
  }

  &__list {
    margin: 5px 15px 20px;
    width: 100%;
    position: relative;
    @include theme.respond-to(mobile) {
      margin: 0;
    }
    @include theme.respond-to(largedesktop) {
      width: 50%;
    }
  }

  &__chat-wrapper {
    height: 440px;
    padding-bottom: 10px;
    margin-bottom: 18px;

    @include theme.respond-to(tablets) {
      height: 500px;
    }
  }

  &__start-message {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 36px;
    font-weight: bold;
    height: 100%;
  }
}