@use "../../../components/theme/index" as theme;

.locations {
  margin-top: 15px;
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    background-color: theme.$grey;
    color: theme.$darkGrey;
    cursor: pointer;
    padding: 0 10px;
    border: none;
    width: 100%;

    @include theme.respond-to(tablets) {
      padding: 0 87px;
    }

    // &:focus {
    //   outline: none;
    // }
  }

  &__title {
    text-transform: uppercase;
    font-weight: bold;
    user-select: none;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 20px;
    white-space: inherit;
    text-align: left;

    @include theme.respond-to(tablets) {
      font-size: 36px;
      white-space: nowrap;
    }
  }

  &__content {
    padding: 34px 10px;

    @include theme.respond-to(tablets) {
      padding: 34px 75px;
    }
  }

  &__arrow {
    width: 28px;
    height: 28px;
  }
}

.ReactCollapse--collapse {
  transition: height 600ms;
}
