@use "../theme/index" as theme;

.connect-line-color{
  background-color: theme.$green;
}

.connect-line{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100px;
  color: theme.$white;
  font-size: 12px;
  padding-left: 3%;
  padding-right: 3%;

  @include theme.respond-to(tablets) {
    flex-direction: row;
    font-size: 18px;
    height: 90px;
  }
  @include theme.respond-to(desktop) {
    font-size: 28px;
    height: 120px;
  }
  @include theme.respond-to(largedesktop) {
    font-size: 38px;
    height: 150px;
  }
  @include theme.respond-to(widedesktop) {
    font-size: 48px;
  }

  &__info{
    display: flex;
    align-items: center;
    width: 90%;
    height: 70px;
    justify-content: space-between;
    @include theme.respond-to(tablets) {
      width: 48%;
    }

    &__connect{
      max-width: 814px;
    }

    &__get{
      max-width: 704px;
    }

    &__connect-button{
      -webkit-tap-highlight-color: transparent;

      &:hover{
        .icon-svg{
          fill: theme.$white;
        }
      }
    }

    &__bird-picture{
      height: 31px;
      width: 31px;
      @include theme.respond-to(desktop) {
        height: 52px;
        width: 52px;
      }
      @include theme.respond-to(largedesktop) {
        height: 70px;
        width: 70px;
      }
      @include theme.respond-to(widedesktop) {
        height: 86px;
        width: 86px;
      }
    }

    &__square-picture{
      height: 30px;
      width: 30px;
      @include theme.respond-to(desktop) {
        height: 49px;
        width: 49px;
      }
      @include theme.respond-to(largedesktop) {
        height: 65px;
        width: 65px;
      }
      @include theme.respond-to(widedesktop) {
        height: 87px;
        width: 87px;
      }
    }

    &__download-app{
      -webkit-tap-highlight-color: transparent;
      height: 30px;
      width: 85px;
      @include theme.respond-to(desktop) {
        height: 50px;
        width: 120px;
      }
      @include theme.respond-to(largedesktop) {
        width: 150px;
      }
      @include theme.respond-to(widedesktop) {
        width: 182px;
      }
    }

    &__download-google{
      -webkit-tap-highlight-color: transparent;
      height: 30px;
      width: 95px;
      @include theme.respond-to(desktop) {
        height: 50px;
        width: 132px;
      }
      @include theme.respond-to(largedesktop) {
        width: 165px;
      }
      @include theme.respond-to(widedesktop) {
        width: 201px;
      }
    }
  }
}
