@use "../../components/theme/index" as theme;

.library {
  &__filters {
    position: relative;
  }

  &__title {
    font-size: 48px;
    font-weight: bold;
    text-align: left;
    color: theme.$darkGrey;
    margin: 20px 10px 10px;
    text-transform: uppercase;

    @include theme.respond-to(tablets) {
      margin: 0 0 44px 51px;
    }
  }

  &__icon {
    width: 36px;
    height: 33px;
    margin-right: 6px;
  }

  &__image {
    max-height: 200px;
    margin-bottom: 60px;

    @include theme.respond-to(tablets) {
      max-height: 300px;
    }
  }

  .loader {
    display: flex;
    justify-content: center;
  }
}
