@use "../theme/index" as theme;

.not-found {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0px 6px 0px 6px;

  &__text {
    text-align: center;
    margin-bottom: 40px;
    font-size: 22px;
    text-transform: none;

    @include theme.respond-to(tablets) {
      font-size: 28px;
    }
  }

  &__image {
    max-height: 200px;
    margin-bottom: 40px;

    @include theme.respond-to(tablets) {
      max-height: 300px;
    }
  }
}
