@use 'media';

@mixin textSize {
  font-size: 16px;
  @include media.respond-to(desktop) {
    font-size: 1.2vw;
  }
  @include media.respond-to(fullHD) {
    font-size: 20px;
  }
}