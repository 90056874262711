@use "../theme/index" as theme;

.input {
  display: flex;
  flex-direction: column;
  @include theme.textSize;

  & input:focus {
    outline: none;
  }

  & {
    min-height: 6.5em;
  }

  &__label {
    display: flex;
    flex-direction: column;

    &-text {
      margin-bottom: 10px;
    }
  }

  &__required {
    color: theme.$darkRed;
  }

  &__field {
    padding: 10px;
    line-height: 1.5;
    border: 1px solid theme.$black;

    &--error {
      border: 1px solid theme.$darkRed;
    }
  }

  &__error {
    display: block;
    color: theme.$darkRed;
    font-size: 16px;
  }
}