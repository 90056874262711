@use "../../../theme/index" as theme;

.xpedition-item {
  &__header {
    height: 60px;
    background-color: theme.$grey;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }

  &__title {
    margin: 0 0 0 20px;
    padding: 0;
    font-size: 30px;
    text-transform: capitalize;
  }

  &__body {
    border-bottom: 1px solid theme.$darkGrey;
    padding-bottom: 20px;

    @include theme.respond-to(tablets) {
      display: flex;
    }
  }

  &__image {
    width: 100%;

    &-wrapper {
      max-width: 20%;
    }
  }

  &__description {
    padding: 20px;
    flex: 1 1 auto;
    @include theme.respond-to(tablets) {
      padding: 0 20px;
    }
  }

  &__button {
    & {
      width: 100%;
      border: none;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      padding-bottom: 20px;
    }

    &:hover {
      transition: color 0.3s;
      color: theme.$silver;
    }

    &-percent {
      font-size: 48px;
      font-weight: bold;
      display: block;
      margin-top: 50px;
    }

    &-label {
      text-transform: capitalize;
      font-weight: bold;
      font-size: 20px;
    }

    &-wrapper {
      min-width: 25%;
    }

    &--remove {
      margin-top: 10px;
      width: 100%;
    }
  }
}